@import 'src/variables';
@import 'src/mixins';

.container {
  display: flex;

  .page {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: $mobile-max-screen-width) {
    & {
      flex-direction: column;

      .page {
        flex: 1;
      }
    }
  }
}

.layoutContent {
  background-color: #f5f5f5;
}
