@import 'src/variables';

.buttons {
  margin-top: $common-xs-padding;
}

.profileEditContainer {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  /* System Colors/White */
  background: #ffffff;

  /* System Colors/Container Stroke */
  border: 1px solid #cad2e6;
  border-radius: 5px;

  .rowLabel {
    margin: 0;
    flex: none;
    flex-grow: 0;
  }
  .rowDescription {
    padding-top: 8px;
    /* Body/Body - Small */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    /* System Colors/Sub Text */
    color: #6a6a6a;
  }

  .fieldName {
    //margin-bottom: 16px;
    font-weight: 400;
    margin: 0;
    display: flex;
    align-items: center;
    color: #080c12 !important;
  }
  .parentFieldName {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    color: #a5a5a5 !important;
    padding-bottom: 16px;
  }
  .fieldValue {
    margin-bottom: 24px;

    font-family: 'Poppins';
    line-height: 24px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    color: #a5a5a5 !important;
    flex: none;
    flex-grow: 0;
  }
  .bottomFieldValue {
    margin-bottom: 0px;

    font-family: 'Poppins';
    line-height: 24px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    color: #a5a5a5 !important;
    flex: none;
    flex-grow: 0;
  }
  .standaloneValue {
    margin-bottom: 0px;

    font-family: 'Poppins';
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #080c12;
    flex: none;
    flex-grow: 0;
  }

  .inputField {
    //margin-right: $common-md-padding;
    margin-bottom: 24px;
    margin-top: 16px;
    display: flex;
    // border: 1px solid #CAD2E6;
    // border-radius: 5px;
  }
  .inputFieldLeft {
    padding-right: 16px;

    margin-bottom: 24px;
    margin-top: 16px;
    display: flex;
    // border: 1px solid #CAD2E6;
    // border-radius: 5px;
  }
  .selectFieldWrapper {
    padding-right: 24px;
  }

  .divider {
    /* System Colors/Container Stroke */
    border: 1px solid #cad2e6;
    margin: 24px 0;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }

  .photoHelperList {
    padding-top: 16px;

    > li {
      list-style: disc;
      margin-left: 16px;

      /* Body/Body - Small */
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      /* System Colors/Sub Text */

      color: #6a6a6a;
    }
  }

  .fileUploader {
    margin-bottom: 16px;
  }

  @media (max-width: $mobile-max-screen-width) {
    .row {
      padding-top: 24px;
    }
  }
}
