@import 'src/mixins';
@import 'src/variables';

.settingsContent {
  min-height: calc(100vh - (210px + 70px));
}

.settingsHeader {
  @include themify {
    background-color: $header-background;
  }

  padding-left: 24px;
  padding-right: 24px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
  will-change: transform;
}

.subHeaderTextWrapper {
  gap: $common-xs-padding * 2;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;

  @media (max-width: $mobile-max-screen-width) {
    align-items: stretch;
    flex-direction: column;
  }
}

.subHeaderActions {
  gap: $common-xs-padding * 3;
  flex-shrink: 0;

  button {
    min-width: 92px;
  }

  @media (max-width: $mobile-max-screen-width) {
    button {
      width: 100%;
    }
  }
}
