@import 'src/variables';
@import 'src/mixins';

.card {
  @include setCardStyles;

  padding: $common-lg-padding;
  padding-left: $common-md-padding;

  .cardContent {
    padding: 0;

    .title {
      color: #080c12;
      padding-bottom: 16px;
    }
  }
}

.cardContainer {
  display: flex;
  flex-grow: 1;

  // .highlight {
  //   width: 7px;
  //   background: #EB3927;
  //   margin-right: $common-md-padding;
  //   border-radius: 5px;
  // }

  .content {
    flex-grow: 1;
  }
}

.cardHeader {
  padding: 0 0 $common-xs-padding;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.cardHeaderAction {
  margin: 0;
  align-self: unset;
}

.cardTitle {
  @include setUppercaseTitle;
  text-transform: none;

  display: inline-flex;
  align-items: center;

  & > * {
    margin: 0 $common-xs-padding 0 0;
  }
}
