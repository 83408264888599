@import 'src/variables';
@import 'src/mixins';


.nccerBadgeBlue{
  background: #172535;
  border-radius: 0px;

  .cardTitle {
    color:#172535;
    background: #A1C5D3;
    text-align: center;
    font-weight: 500;
    font-size: x-small;
    width: 100%;
    padding: 10px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .craftTitle {
    color:#172535;
    background: #FD6A3C;
    text-align: center;
    font-weight: 500;
    font-size: x-small;
    width: 100%;
    padding: 10px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .boxGrid {
    background:#172535;
    border: 1px solid #A1C5D3;
    padding-top: 10px;
  }
  .craftBoxGrid {
    background:#172535;
    border: 1px solid #FD6A3C;
    padding-top: 10px;
  }
  .completion {
    color:#ffffff;
    text-align: center;
    width: 100%;
  }
}

.nccerBadgeLevel{
  background: #A1C5D3;
  border-radius: 0px;
  
  .cardTitle {
    color:#172535;
    border: 1px solid #172535;
    text-align: center;
    font-weight: 500;
    font-size: x-small;
    width: 100%;
    padding: 10px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .singleLevelTitle {
    color:#172535;
    background: #cccfd3;
    border: 1px solid #172535;
    text-align: center;
    font-weight: 500;
    font-size: x-small;
    width: 100%;
    padding: 10px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .boxGrid {
    background:#172535;
    padding-top: 10px;
  }
  .completion {
    color:#ffffff;
    text-align: center;
    width: 100%;
  }
}
.nccerBadgeSingleLevel{
  background: #cccfd3;
}
.nccerBadgeLevelOne{
  background: #FFD540
}
.nccerBadgeLevelTwo{
  background: #FD6A3C
}
.nccerBadgeLevelThree{
  background: #A1C5D3;
}
.nccerBadgeLevelFour{
  background: #70BEB4;
}
.nccerBadgeLevelFive{
  background: #6E96D6;
}

.credentialCard {
  padding: 16px;
  aspect-ratio: 1 / 1;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  .cardTitle {
    text-align: center;
    font-weight: 500;
    width: 100%;
    line-height: 1.15;
  }
  .detailsLink {
    width: 100%;
    margin-top: auto;
  }
  .printCredentialBtn {
    align-self: flex-end;
    margin-top: auto;
    width: 100%;

    @media (max-width: $mobile-max-screen-width) {
      width: 100%;
    }
  }
  .stamp {
    float: right;
    padding-left: 8px;
  }
  .cardBadge {
    max-width: 100%;
    margin-bottom: 8px;
  }

 
}