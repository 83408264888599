@import '~normalize.css';
@import 'src/mixins';

$default-font-size: 16px;

html,
body {
  height: 100%;
}

#root {
  height: 100vh;

  .rootContent {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $default-font-size;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  background: white;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

// Moved to Layout.tsx
// h1 {
//   font-family: 'Circe Bold', sans-serif;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 32px;
//   line-height: 32px;
// }

// h2 {
//   font-family: 'Circe Bold', sans-serif;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 24px;
//   line-height: 29px;
// }

// h5 {
//   font-family: 'Circe Bold', sans-serif;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 18px;
//   line-height: 21px;
// }

// h6 {
//   font-family: 'Circe Bold', sans-serif;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 18px;
//   line-height: 21px;
// }

// removing google chrome autocomplete inputs styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// UNIVERS IS LEGACY!
// $univers-font-path: '/assets/fonts/Univers';
// // basic lists of font-family values
// $univers-font-family-name: 'Univers';
// $univers-font-configs: (
//         ('#{$univers-font-path}/UniversLTStd-Light.otf', 400),
//         ('#{$univers-font-path}/UniversLTStd-LightObl.otf', 400, italic),
//         ('#{$univers-font-path}/UniversLTStd.otf', 500),
//         ('#{$univers-font-path}/UniversLTStd-Obl.otf', 500, italic),
//         ('#{$univers-font-path}/UniversLTStd-Bold.otf', 700),
//         ('#{$univers-font-path}/UniversLTStd-BoldObl.otf', 700, italic),
// );
// $univers-cn-font-family-name: 'Univers Condensed';
// $univers-cn-font-configs: (
//         ('#{$univers-font-path}/UniversLTStd-LightCn.otf', 400),
//         ('#{$univers-font-path}/UniversLTStd-LightCnObl.otf', 400, italic),
//         ('#{$univers-font-path}/UniversLTStd-Cn.otf', 500),
//         ('#{$univers-font-path}/UniversLTStd-CnObl.otf', 500, italic),
//         ('#{$univers-font-path}/UniversLTStd-BoldCn.otf', 700),
//         ('#{$univers-font-path}/UniversLTStd-BoldCnObl.otf', 700, italic),
// );

$poppins-font-path: '/assets/fonts/Poppins';
// basic lists of font-family values
$poppins-font-family-name: 'Poppins';
$poppins-font-configs: (
  ('#{$poppins-font-path}/Poppins-Light.ttf', 300),
  ('#{$poppins-font-path}/Poppins-LightItalic.ttf', 300, italic),
  ('#{$poppins-font-path}/Poppins-Regular.ttf', 400),
  ('#{$poppins-font-path}/Poppins-Italic.ttf', 400, italic),
  ('#{$poppins-font-path}/Poppins-Medium.ttf', 500),
  ('#{$poppins-font-path}/Poppins-MediumItalic.ttf', 500, italic),
  ('#{$poppins-font-path}/Poppins-Bold.ttf', 700),
  ('#{$poppins-font-path}/Poppins-BoldItalic.ttf', 700, italic)
);

// $notoSans-font-path: "/assets/fonts/Noto_Sans";
// // basic lists of font-family values
// $notoSans-font-family-name: "Noto Sans";
// $notoSans-font-configs: (
//   ("#{$notoSans-font-path}/NotoSans-Light.ttf", 300),
//   ("#{$notoSans-font-path}/NotoSans-LightItalic.ttf", 300, italic),
//   ("#{$notoSans-font-path}/NotoSans-Regular.ttf", 400),
//   ("#{$notoSans-font-path}/NotoSans-Italic.ttf", 400, italic),
//   ("#{$notoSans-font-path}/NotoSans-Medium.ttf", 500),
//   ("#{$notoSans-font-path}/NotoSans-MediumItalic.ttf", 500, italic),
//   ("#{$notoSans-font-path}/NotoSans-Bold.ttf", 700),
//   ("#{$notoSans-font-path}/NotoSans-BoldItalic.ttf", 700, italic)
// );

$circe-font-path: '/assets/fonts/Circe';
// basic lists of font-family values
$circe-font-family-name: 'Circe';
$circe-font-configs: (
  ('#{$circe-font-path}/CRC25.otf', 300),
  // extra light 300
  ('#{$circe-font-path}/CRC35.otf', 350),
  // light 350
  ('#{$circe-font-path}/CRC55.otf', 400),
  // normal 400
  ('#{$circe-font-path}/CRC65.otf', 700),
  // bold 700
);

/* setup fonts */
// @include setFontFaces($univers-font-family-name, $univers-font-configs);
// @include setFontFaces($univers-cn-font-family-name, $univers-cn-font-configs);

@include setFontFaces($poppins-font-family-name, $poppins-font-configs);
@include setFontFaces($circe-font-family-name, $circe-font-configs);
