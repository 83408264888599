@import 'src/variables';

.backdrop {
  z-index: 2;
  flex-direction: column;
}

.label {
  margin-top: $common-sm-padding;
}

.container {
  position: relative;

  &.loading {
    pointer-events: none;
  }
}

.filled {
  background-color: white;
  z-index: 1;
}

.spinnerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  .spinner {
    position: sticky;
    top: 35vh;
    left: 0;
    right: 0;
    height: min(100%, 30vh);
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;
    overflow: hidden;
  }

  & ~ * {
    opacity: 0.5;
  }

  &.filled {
    & ~ * {
      visibility: hidden;
    }
  }
}
