@import 'src/variables';
@import 'src/mixins';

$sidebar-background-color: $nccer-darkblue;
$sidebar-menu-item-active-color: white;
$sidebar-menu-icon-size: 38px;
$sidebar-list-item-icon-size: 30px;
$sidebar-padding-horizontal: 25px;
$sidebar-padding-vertical: $common-xs-padding;
$sidebar-collapse-icon-size: 20px;
$sidebar-collapsed-width: $sidebar-padding-horizontal * 2 + $sidebar-menu-icon-size;
$sidebar-header-height: 70px;
$sidebar-list-item-padding: 90px;
$sidebar-list-padding: 37px;
$nccer-logo-size: 75px;

$header-height: 390px;
$header-collapsed-height: $nccer-logo-size + 2 * $common-sm-padding;
$nccer-title-size: 200px;

.sidebar {
  position: sticky;
  top: 0;
  background: $sidebar-background-color;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: $sidebar-width;
  transition: $common-transition;
  z-index: 2;

  & + * {
    transition: $common-transition;
    width: calc(100% - #{$sidebar-width});

    footer {
      padding-left: $sidebar-width;
    }
  }

  .menuIcon {
    display: none;
  }

  &.collapsed {
    width: $sidebar-collapsed-width;

    & + * {
      width: calc(100% - #{$sidebar-collapsed-width});

      footer {
        padding-left: $sidebar-collapsed-width;
      }
    }
  }

  .sidebarHeader {
    display: none;

    .collapseIcon {
      justify-content: flex-end;
    }

    .craftProLogoWrapper {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
    }

    .nccerLogo {
      margin-right: auto;

      img {
        width: 100%;
      }

      @media (max-width: $mobile-max-screen-width) {
        padding-left: $common-md-padding;
        padding-right: $common-md-padding;

        &.nccerSidebarHeader {
          height: auto;

          .nccerContainer {
            display: flex;
            align-items: center;
            margin-right: auto;
            width: 100%;

            .nccerTitle {
              margin: 0 $common-xs-padding 0 $common-xs-padding;
              text-align: center;
              font-weight: 700;
              font-size: 15px;
              @include themify {
                color: $header-text-color;
              }
            }
          }
        }
      }
    }
  }

  .sidebarContent {
    background: #172535;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    height: 100%;
  }

  @media (max-width: $mobile-max-screen-width) {
    &,
    &.collapsed {
      width: 100%;
      height: auto;
      position: static;
    }

    &.collapsed + *,
    & + * {
      width: 100%;

      footer {
        padding-left: 0;
      }
    }

    &.menuVisible {
      position: sticky;
      .sidebarHeader {
        position: sticky;
      }

      .sidebarContent {
        max-height: unset;
        height: calc(100vh - #{$sidebar-header-height});
      }
    }

    .sidebarHeader {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      transition: all 0.3s ease-in-out;
      padding: 8px 15px;
      background-color: #172535;
      width: 100%;
    }

    .logo {
      @include setSize(60px);
      margin-right: auto;
      margin-top: auto;

      img {
        height: 100%;
      }
    }

    .sidebarContent {
      background: $sidebar-background-color;
      transition: max-height 0.25s ease-out;
      z-index: 1000;
      top: $sidebar-header-height;
      height: 100%;
      width: 100%;
      overflow: hidden;
      max-height: 0;
    }

    .menuIcon {
      display: inherit;
    }
  }
}

.auxButtonGroup {
  margin: $common-sm-padding;
  margin-top: auto;

  .needHelpBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 4px;

    //width: 208px;
    min-width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    //height: 170px;

    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;

    .needHelpTextBox {
      /* Auto layout */
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 4px;

      .needHelpTitle {
        // width: 91px;
        height: 24px;

        /* Body/Body - Large */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* Neutrals/White */
        color: #ffffff;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .needHelpDescription {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* or 150% */
        text-align: center;

        /* Neutrals/Gray/Gray 75 */
        color: #d1d1d1;
      }

      .needHelpButton {
        /* Primary Button Small */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        gap: 8px;

        width: 176px;
        height: 40px;

        /* Primary/Dark Navy/Dark Navy */
        background: #172535;
        border-radius: 5px;
      }
    }

    .needHelpButtonMobile {
      /* Primary Button Small */

      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      gap: 8px;
      background: #ffffff;

      width: 176px;
      height: 40px;

      /* Primary/Dark Navy/Dark Navy */
      background: #172535;
      border-radius: 5px;
    }
  }

  .auxiliaryButton {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
  }

  .auxiliaryButtonMobile {
    font-family: 'Poppins';
    background-color: #ffffff;
    border-color: #cad2e6;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
  }

  .legalInfoLink {
    display: block;

    padding-top: $common-xs-padding;
    padding-bottom: calc($common-xs-padding / 2);
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: normal;
    line-height: 14px;
    text-align: center;
    text-decoration: underline;
    color: #ffffff;
    min-width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
}

.menuViewWrapper {
  background: $sidebar-background-color;
  transition: max-height 0.25s ease-out;
  z-index: 1000;
  top: $sidebar-header-height;
  height: 100%;
  width: 100%;
  overflow: auto;

  .profileInfo {
    padding: 0 16px;

    @media (min-width: $mobile-max-screen-width) {
      display: none;
    }

    .divider {
      width: 100%;
      border-color: #ffffff;
      margin-top: $common-sm-padding;
      margin-bottom: $common-lg-padding;
    }
  }

  .profileImageWrapper {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding-top: 8px;
    padding-left: 8px;

    .profileImage {
      max-height: 72.75px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  .textItems {
    padding-left: 16px;
    .nameplate {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      padding-top: 8px;

      @include themify {
        color: #ffffff;
      }
    }

    .nccerNumberLabel {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;

      @include themify {
        color: #ffffff;
      }
    }
  }
}

.menu {
  list-style: none;
  padding: $sidebar-padding-vertical 15px;

  .link {
    @include themify {
      color: #ffffff;
    }

    background-color: $nccer-darkblue;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    width: 100%;

    .content {
      display: inline-flex;
      align-items: center;
      background-color: transparent;
      width: 100%;
      height: 100%;
      padding: $common-xs-padding $common-sm-padding;
      font-size: 20px;
      line-height: 25px;
      border-radius: 5px;
      margin: 5px 0;
      border-left: 5px white;

      @media (max-width: $mobile-max-screen-width) {
        padding-left: $common-md-padding;
        padding-right: $common-md-padding;
      }
    }

    .icon {
      @include setIconSize($sidebar-menu-icon-size);
    }

    .label {
      white-space: nowrap;
      transition: opacity 0.15s ease-in-out 0.3s;
      margin-left: 12px;
      margin-top: calc($common-xs-padding / 2);
      margin-bottom: calc($common-xs-padding / 2);

      //styleName: Body/Body - Large;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    .smallLabel {
      // body 2 styles
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-left: 20%;
    }

    &.active {
      color: #080c12;

      .icon {
        fill: #080c12;
        stroke: #080c12;
      }
    }

    &:hover {
      .content {
        background-color: #2c3c4d;
      }
    }

    &.active {
      .content {
        @include themify {
          background-color: $sidebar-item-background-color;
        }
        font-weight: 600;
      }
    }
  }

  .listLink {
    .content {
      padding-left: $common-sm-padding; // $sidebar-list-item-padding;
      height: $sidebar-menu-icon-size + $sidebar-padding-vertical * 2;
    }

    .icon {
      display: none;
      @include setIconSize($sidebar-list-item-icon-size);
    }
  }

  .divider {
    min-width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: 0px;
    border: 1px solid rgba(209, 209, 209, 0.5);
  }

  &.collapsed {
    .label {
      opacity: 0;
      width: 0;
      visibility: hidden;
      color: $sidebar-menu-item-active-color;
      transition: none;
    }

    .link:hover {
      .content {
        width: auto;
        .label {
          z-index: 0;
          opacity: 1;
          visibility: visible;
          width: auto;
          margin-left: $common-sm-padding;
          padding-left: $sidebar-padding-horizontal;
        }
      }
    }
    .content {
      .label {
        margin-left: 0;
      }
    }
    .listLink {
      .content {
        padding: $common-xs-padding $common-sm-padding;
      }
      .icon {
        display: block;
      }
    }
  }
}

.nccerContainer {
  display: flex;
  align-items: center;

  .nccerLogo {
    align-self: flex-start;
    display: block;

    img {
      max-width: 100%;
      max-height: 66px;
    }

    @media (max-width: $mobile-max-screen-width) {
      display: none;
    }
  }
}
