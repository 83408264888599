@import 'src/variables';
@import 'src/mixins';

.myCredentialsHeader {
  @include themify {
    background-color: $header-background;
  }

  padding: 24px;
  border-bottom: 1px solid #cad2e6;
  position: sticky;

  @media (min-width: $mobile-max-screen-width) {
    padding-bottom: 0;
  }

  .myCredsTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0;

    color: #080c12;
  }

  .myCredsDescription {
    margin: 0;
    padding-top: $common-xs-padding * 2;
    padding-bottom: $common-xs-padding * 2;

    @media (max-width: $mobile-max-screen-width) {
      padding-bottom: 0;
    }
  }

  .printTranscriptButton {
    @media (max-width: $mobile-max-screen-width) {
      width: 100%;
    }
  }
}

.credentialSubHeaderTextWrapper {
  align-items: center;
  display: flex;
  padding-top: $common-xs-padding * 2;

  .subHeaderActions {
    float: right;
  }
}

.cardIcon {
  display: flex;
  justify-content: center;
}

.walletCardActionBtn {
  margin-bottom: 16px;
}

.divider {
  /* System Colors/Container Stroke */
  border: 1px solid #cad2e6;
  margin: 24px 0;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.credlyLink {
  text-decoration: underline;
  font-style: italic;
}
