@import 'src/variables';
@import 'src/mixins';

.reportsHeader {
  @include themify {
    background-color: $header-background;
  }

  padding: 24px;
  border-bottom: 1px solid #cad2e6;
  position: sticky;

  .reportsTitle {
    // font-style: normal;
    // font-weight: 700;
    // font-size: 24px;
    // line-height: 29px;
    margin: 0;

    color: #080c12;
  }

  .reportsDescription {
    margin: 0;
    padding-top: $common-xs-padding * 2;
    //padding-bottom: $common-xs-padding * 4;
  }

  .printTranscriptButton {
    float: right;

    @media (max-width: $mobile-max-screen-width) {
      width: 100%;
    }
  }
}

.tabContainer {
  width: 100%;
}

.reportsTable {
  box-shadow: none;
  border: 1px solid #cad2e6;
}
