@import 'src/variables';
@import 'src/mixins';
@import 'src/themify';

$modal-padding: 32px;

.paper {
  width: 100%;
}

.header {
  padding: $modal-padding;
  padding-bottom: 0;

  .title {
    color: #080c12;
    padding-bottom: 8px;
    font-weight: 600;
  }

  .subTitleText {
    color: #6a6a6a;
    padding-bottom: 16px;
  }

  .closeButton {
    padding: 0;
    margin-left: auto;
    float: right;

    svg {
      @include setIconSize(24px);
    }
  }
}

.content {
  &,
  &:first-child {
    padding: $modal-padding;

    @media (max-width: $mobile-max-screen-width) {
      padding: calc(#{$modal-padding} / 2) $modal-padding;
    }
  }
}

.footer {
  padding: calc(#{$modal-padding} / 2) $modal-padding;
}

.footerRow {
  @media (max-width: $mobile-max-screen-width) {
    flex-direction: column;

    & > button {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: calc(#{$modal-padding} / 2);
      }
    }
  }
}

.form,
.spinner {
  height: 100%;
  overflow-x: hidden;
}
