@import 'src/variables';
@import 'src/mixins';

.credentialBadgeWrapper {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;

  .badge {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    max-width: 500px;
    aspect-ratio: 1 / 1;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;

    .badgeNameWrapper {
      display: flex;
      align-items: center;
      align-self: center;
      height: 3em;

      .badgeName {
        font-weight: 500;
        padding: 0 24px 0 24px;
      }
    }

    .expirationTagWrapper {
      display: grid;
      grid-template-columns: repeat(3, minmax(auto, 1fr));
      grid-template-rows: repeat(3, minmax(auto, 1fr));

      .expiredTag {
        background: #ffeee7;
        color: #eb3927;
        font-weight: bold;
        margin-top: 8px;
        margin-right: 8px;
        padding: 10px 16px 10px 16px;
        border-radius: 2.5px;
        text-align: center;
        grid-column: 3/3;
      }
      .hidden {
        // dynamic rendering of element impedes grid spacing
        // so we draw but hide if it shouldn't display
        opacity: 0;
      }
    }

    .levelTextColor {
      color: #ffffff;
    }
    .moduleTextColor {
      color: #172535;
    }
    .partialTextColor {
      color: #000000;
    }
  }
}

.badge:hover .expirationTagWrapper {
  @media (width > $mobile-max-screen-width) {
    opacity: 0.5;
    display: grid;
    grid-template-rows: repeat(2, minmax(auto, 1fr));
  }
}
.badge:hover .badgeNameWrapper {
  @media (width > $mobile-max-screen-width) {
    display: grid;
    grid-template-rows: repeat(2, minmax(auto, 1fr));
  }

  .badgeName {
    @media (min-width: $mobile-max-screen-width) {
      display: none;
    }
  }
  .downloadLink {
    display: grid;
    grid-row: 1/2;
    margin-top: 8px;
  }
  .viewDetailsLink {
    display: grid;
    grid-row: 2/2;
    margin-top: 16px;
  }
}

.drawerWrapper {
  padding: 16px;
  min-height: 4em;

  :nth-child(2) {
    margin-top: 16px;
  }

  .drawerOption {
    width: 100%;
    background: #f3f3f3;
    float: left;

    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 8px;
    border-radius: 5px;
  }

  .mobileDownloadLink {
    margin-bottom: 16px;
  }
  .mobileDetailsLink {
    margin-bottom: 8px;
  }
}

.credentialCard {
  padding: 16px;

  .cardTitle {
    text-align: start;
    font-weight: 600;
    width: 100%;
  }
  .detailsLink {
    width: 100%;
    margin-top: auto;
  }
  .printCredentialBtn {
    align-self: flex-end;
    margin-top: auto;
    width: 100%;

    @media (max-width: $mobile-max-screen-width) {
      width: 100%;
    }
  }
  .stamp {
    float: right;
    padding-left: 8px;
  }
  .cardBadge {
    max-width: 100%;
    margin-bottom: 8px;
  }
}
