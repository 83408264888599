@import 'src/variables';
@import 'src/themify';

$sidebar-menu-button-size: 42px;
$sidebar-menu-button-height: 30px;

.button {
  border: none;
  padding: 0;
  min-width: $sidebar-menu-button-size;
  max-width: $sidebar-menu-button-size;

  &,
  &:hover,
  &:focus {
    background-color: transparent;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: $sidebar-menu-button-size;

  .bar {
    @include themify {
      background-color: $header-background;
    }

    display: block;
    height: 3px;
    border: 0;
    border-radius: 1.5px;
    width: 100%;
    transition: all 0.25s ease-out;
    transform-origin: 1px;
  }

  &.rightAlignView,
  &.leftAlignView {
    height: $sidebar-menu-button-height;

    .bar {
      &:nth-child(2) {
        width: 24px;
      }

      &:last-child {
        width: 34px;
      }
    }
  }

  &.crossView {
    .bar {
      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: translateX(20px);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  &.rightAlignView {
    align-items: flex-end;
  }

  &.leftAlignView {
    align-items: flex-start;
  }
}
