@import 'src/variables';
@import 'src/mixins';

.verifyCredentialsHeader {
  @include themify {
    background-color: $header-background;
  }

  padding: 24px;
  border-bottom: 1px solid #cad2e6;
  position: sticky;

  .title {
    margin: 0;
    padding-bottom: $common-xs-padding * 2;

    color: #080c12;
  }

  .description {
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .printTranscriptButton {
    float: right;
  }
  .searchIcon {
    height: 24px;
    padding-left: $common-xs-padding;
  }
  .searchBar > * {
    min-height: 48px;
  }
  .searchButton {
    justify-content: center;
    max-height: 56px;
    line-height: 1;
    min-width: 8em;

    .text {
      color: #3f5e9d;
      padding: 10px 20px 10px 8px;
    }
  }
  .exportButton {
    justify-content: center;
    max-height: 56px;
    line-height: 1;
    min-width: 10em;

    .exportText {
      color: white;
      padding: 10px 0px;
    }
  }
}

.credentialCard {
  margin-bottom: $common-xs-padding * 3;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $common-xs-padding * 3;

  /* System Colors/White */

  background: #ffffff;
  /* System Colors/Container Stroke */

  border: 1px solid #cad2e6;
  border-radius: 5px;

  .viewCredsBtn {
    float: right;

    .text {
      font-weight: 600;
      color: #3f5e9d;
    }

    .carrot {
      padding-left: 14px;
    }
  }
}
