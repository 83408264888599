@import 'src/variables';
@import 'src/mixins';
@import 'src/themify';

.quickLinks {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin: 0 $common-lg-padding $common-xs-padding 0;
  }

  .quickLinkButton {
    min-height: 28px;
    font-weight: 700;
    font-size: 15px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.label {
  @include themify {
    color: $header-text-color;
  }

  font-weight: 700;
  font-size: 18px;
}

.cardContent {
  .button {
    margin-top: $common-md-padding;
  }
}

.viewAllButton {
  @include setLinkStyles(inherit);

  text-decoration: none;
  margin-left: $common-md-padding;

  &.disabled {
    color: $grey-disabled;
    pointer-events: none;
  }
}

.divider {
  margin: $common-sm-padding 0;
}

.cardNumberInfo {
  display: flex;
  justify-content: flex-end;

  .cardNumber {
    text-transform: uppercase;
  }
}

.title {
  //padding-bottom: 8px;
  margin-bottom: 8px;
  margin-top: 0;
}

.subTitle {
  @include themify {
    color: $text-color;
  }
  padding-bottom: 8px;
}

.productCard {
  height: 100%;
  min-height: 200px;
  //width: 368px;
  left: 200px;
  top: 100px;
  border: 1px solid #cad2e6;
  border-radius: 5px;
  box-shadow: none;

  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  //gap: 2em;

  &:hover {
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.05);

    .launchBtnCarrot {
      padding-left: 16px;
    }
  }

  .contentBox {
    padding: 0;
  }

  .productCardHeader {
    margin: 0;
    padding-top: 0.67em;
    width: 100%;
    color: #172535;
    text-align: initial;
  }

  .productCardIconWrapper {
    min-width: 48px; // size of icon
    margin-right: 1em;
    text-align: initial;
  }

  .productCardIcon {
    max-height: 48px;
  }

  .productCardBody {
    padding-top: 1em;
    text-align: initial;
  }

  .productCardLink {
    padding: 0;

    .launchBtn {
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      padding: 0;

      .text {
        color: $nccer-cobalt;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 0;
      }
    }

    .launchBtnCarrot {
      padding-left: 8px;
    }
  }
}
