@import 'src/variables';
@import 'src/mixins';

.skillSectionLabel {
  color: #6a6a6a;
}

.skillSummary {
  width: 100%;
  border-bottom: #cad2e6 1px solid;

  .text {
    font-weight: 600;
    width: 100%;
  }
}

.skillDescription {
  display: flex;
  padding-top: 16px;

  .text {
    padding-left: 8px;
  }
}

.badgeImage {
  max-width: 100%;
}
.mobileBadgeWrapper {
  text-align: center;
}

.dateWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.mobileDateWrapper {
  display: inline-flex;
  width: 100%;
  margin-bottom: $common-xs-padding;
}

.coursesTable {
  border: #cad2e6 1px solid;
  border-radius: 5px;
  width: 100%;
  background: #ffffff;

  .titleWrapper {
    text-align: left;
    border-bottom: 1px #cad2e6 solid;
    width: 100%;

    th {
      padding: 16px 24px;
    }

    .title {
      color: black;
    }

    .mobileTitle {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .columnHeader {
    background: #f7f7f7;

    th {
      padding: 14px 24px 14px 0px;
    }
    th:first-child {
      padding: 14px 0px 14px 24px;
    }

    .headerCellWrapper {
      .headerText {
        font-weight: 500;
        color: #393939;
      }
    }
  }

  .dataRow {
    td {
      padding: 16px 24px 16px 0px;
    }
    td:first-child {
      padding: 16px 0px 16px 24px;
    }

    .columnOne {
      width: 33%;

      .text {
        color: #080c12;
      }
    }

    .columnTwo {
      width: 21%;
    }
  }
}

.mobileCoursesTable {
  border: #cad2e6 1px solid;
  border-radius: 5px;
  width: 100%;
  background: #ffffff;

  .titleWrapper {
    text-align: left;
    border-bottom: 1px #cad2e6 solid;
    width: 100%;

    th {
      padding: 16px;
    }

    .title {
      color: black;
    }

    .mobileTitle {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .headerWrapper {
    background: #f7f7f7;

    .header {
      padding: 14px 16px;
      .headerText {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #393939;
      }
    }
  }

  .mobileDataRow {
    border-bottom: 1px #cad2e6 solid;
    border-radius: 0;

    .accordionBox {
      padding: 16px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .expandedAccordionHeader {
        border-bottom: #cad2e6 1px solid;
        padding: 0px 0px 8px 0px;
        min-height: 0;

        :first-child {
          margin: 0px;
        }
      }

      .collapsedAccordionHeader {
        padding: 0;
        min-height: 0;

        :first-child {
          margin: 0px;
        }
      }

      .accordionFaceplate {
        color: #080c12;
        font-weight: 500;
      }

      .accordionContent {
        padding: 0;

        .accordionCategory {
          font-size: 12px;
          line-height: 14px;
          padding-bottom: 8px;
          padding-top: 16px;
        }

        .accordionCategoryValue {
          color: #080c12;
        }
      }
    }
  }
}
