@import 'src/variables';
@import 'src/mixins';

.card {
  @include setCardStyles;

  padding: $common-lg-padding;
  padding-left: $common-md-padding;
  max-height: 192px;
  border: 1px solid #cad2e6;
  border-radius: 5px;
  box-shadow: none;

  .cardContent {
    padding: 0;

    .title {
      color: #080c12;
      padding-bottom: 16px;
    }
  }
}

.cardMobile {
  @include setCardStyles;

  padding: $common-lg-padding;
  padding-left: $common-md-padding;
  border: 1px solid #cad2e6;
  border-radius: 5px;
  box-shadow: none;


  .cardContent {
    padding: 0;

    .title {
      color: #080c12;
      padding-bottom: 16px;
    }
  }
}

.cardContainer {
  display: flex;
  flex-grow: 1;

  .content {
    flex-grow: 1;
  }
}

.cardHeader {
  padding: 0 0 $common-xs-padding;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

.cardHeaderAction {
  margin: 0;
  align-self: unset;
}

.cardTitle {
  @include setUppercaseTitle;
  text-transform: none;

  display: inline-flex;
  align-items: top;

  & > * {
    margin: 0 $common-xs-padding 0 0;
  }
}
.cardBody {
  padding: 0 !important;
   margin: 0 !important;
}
.cardBodyMobile {
  padding: 0;
  margin: 0;
}
.cardText {
  padding: 0 !important;
}
.textContainer {
  padding: 16px!important;
  padding-left: 0!important;
  a {
    max-width: fit-content;
    margin-top: 16px !important;
    padding: 10px 20px;
  }
}
.title {
  margin: 0!important; 
  padding-bottom: 8px;
}

.cardTextMobile {
  padding: 0 !important;
}
.textContainerMobile {
  padding: 16px!important;
  padding-top: 0!important;
  a {
    margin-top: 16px !important;
    padding: 10px 20px;
    width: 100%;
  }
}
.titleMobile {
  margin: 0!important; 
  padding-bottom: 8px;
}
