@import 'src/variables';
@import 'src/mixins';

.pageTitle {
  margin-top: 0;
  //margin-bottom: $common-sm-padding;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.alert {
  margin-bottom: $common-lg-padding;
}

.hidden {
  visibility: hidden;
  z-index: -1;
  height: 0;
  width: 0;
  position: absolute;
  bottom: 10000px;
}

.tab {
  text-transform: uppercase;
  font-weight: 700;
  max-width: 320px; // for support long text
}

.tabPanel {
  padding-left: 0;
  padding-right: 0;
}

.link {
  @include setLinkStyles;
}

.uppercase {
  text-transform: uppercase;
}

.card {
  @include setCardStyles;
}

.text {
  margin: 0;
}

.bold {
  font-weight: bold;
}

.mediumText {
  font-weight: 500;
}

.subTitle {
  @include themify {
    color: $text-color;
  }

  font-size: 18px;
  font-weight: 700;
  margin: 0 0 $common-sm-padding;
}

.dangerButtonContained {
  color: white;
  background-color: $red;

  &:hover {
    background-color: darken($red, 25%);
  }
}

.dangerButtonOutlined {
  color: $red;
  border: 1px solid rgba($red, 0.5);

  &:hover {
    border: 1px solid $red;
    background-color: rgba($red, 0.04);
  }
}

.dangerIconButton {
  color: $red;

  &:hover {
    background-color: rgba($red, 0.04);
  }
}

.gridItemDivider {
  width: 100%;
  margin-top: $common-lg-padding;
}

.bottomBorder {
  border-bottom: 1px solid #cad2e6;
}

.subHeaderTextWrapper {
  align-items: center;
  display: flex;
  padding-top: $common-xs-padding * 4;

  .subHeaderDescription {
    margin: 0;
  }
  .subHeaderActions {
    display: flex;
    justify-content: flex-end;

    .button {
      min-width: 92px;
      margin-left: $common-xs-padding * 3;
    }
  }
}

// material styles
.placeholder {
  opacity: 0.42;
}

.pageContent {
  @include setPageContentStyles(true);
  //background-color: #F5F5F5;
  //overflow: auto;
  padding: 24px;
}

.computerIconBox {
  width: 100%;
  padding-bottom: $common-xs-padding * 3; // 24px;
  display: flex;
  justify-content: center;
}

.contentCard {
  box-sizing: border-box;
  height: 100%;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  /* System Colors/White */
  background: #ffffff;

  /* System Colors/Container Stroke */
  border: 1px solid #cad2e6;
  border-radius: 5px;
}

.tabText {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  max-width: none;
}

.clipboardBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6em;
  gap: 32px;

  .text {
    font-weight: 400;
    color: #6a6a6a;
    max-width: 50%;
    text-align: center;
  }
}

.nameplateWrapper {
  display: inline-flex;

  .nameplate {
    padding-left: 12px;
  }
}

.smallProfileImageWrapper {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  .smallProfileImage {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
}

.buttonIcon {
  padding-right: 8px;
}
.printTranscriptButton {
  float: right;
}
.viewSelectorWrapper {
  align-self: center;

  .viewSelector {
    float: right;
    box-shadow: none;

    .gridViewButton {
      border-radius: 5px 0px 0px 5px;
      border: #cad2e6 1px solid;
      padding: 12px, 10px, 12px, 10px;

      &.activeViewButton {
        background: #f1f3fd;
      }

      .gridViewIcon {
        color: #4a5666;
      }
    }

    .listViewButton {
      border-radius: 0px 5px 5px 0px;
      border-right: #cad2e6 1px solid;
      border-top: #cad2e6 1px solid;
      border-bottom: #cad2e6 1px solid;
      padding: 12px, 10px, 12px, 10px;

      &.activeViewButton {
        background: #f1f3fd;
      }

      .listViewIcon {
        color: #4a5666;
      }
    }

    .viewSelectorText {
      color: #4a5666;
      font-weight: 500;
      padding-left: 5px;
      padding-right: 4px;
    }
  }
}
.listViewTable {
  margin-bottom: $common-md-padding;
}
.credentialCategory {
  margin: 0;
}
.noDocumentMarker {
  color: #cccccc;
  padding-left: 8px;
}

.pageSubHeader {
  @include themify {
    background-color: $header-background;
  }

  padding: 24px;
  border-bottom: 1px solid #cad2e6;
  position: sticky;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0;

    color: #080c12;
  }

  .description {
    margin: 0;
    padding-top: $common-xs-padding * 2;
    //padding-bottom: $common-xs-padding * 4;
  }
}

.credentialCounter {
  background-color: #cccccc;
  padding: 0 8px;
  border-radius: 5px;
}
.ovOverflowEnforcer > div {
  overflow: auto !important;
}

.inputField {
  margin-bottom: 24px;
  margin-top: 16px;
  display: flex;
}

.nccerNumberDisplay {
  color: $nccer-orange;
}

.tabDescriptor {
  color: black;
  padding: 24px 0px;
}
