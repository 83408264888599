@import 'src/variables';
@import 'src/mixins';

.expiredTag {
  background: #ffeee7;
  color: #eb3927;
  font-weight: bold;
  width: fit-content;
  margin-left: auto;
  padding: 8px 8px;
  border-radius: 2.5px;
}

.overlayContainer {
  .hiddenOverlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), black);
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }
  &:hover .hiddenOverlay {
    display: flex;
  }
}

.downloadLink {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 8px;
  border-radius: 2.5px;
  border: 1px solid white;

  &:hover {
    background-color: #919191;
  }
}
.viewDetails {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 8px;
  text-align: center;
}
.viewDetailsLink {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 8px;
  border-radius: 2.5px;
  border: 1px solid white;
  text-align: center;

  &:hover {
    background-color: #919191;
  }
}
