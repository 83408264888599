@import 'src/variables';
@import 'src/themify';

.divider {
  @include themify {
    background-color: $text-color;
  }

  margin: $common-md-padding 0;
}
