@import 'src/variables';
@import 'src/mixins';
@import 'src/themify';

$nccer-logo-size: 100px;
$gamsd-logo-size: 110px;
$header-height: 390px;
$header-collapsed-height: $nccer-logo-size + 2 * $common-sm-padding;
$nccer-title-size: 200px;

.header {
  @include themify {
    background-color: $header-background;
  }

  border-bottom: 1px solid #cad2e6;
  width: 100%;
  //min-height: $header-collapsed-height;
  //height: auto;
  //display: flex;
  //flex-direction: column;

  height: 5em; // 100px;
  left: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  //gap: 24px;

  // .content {
  //   flex-direction: row;
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }

  .userText {
    //width: 100%;
    //margin-right: $nccer-logo-size + $common-sm-padding + $nccer-title-size;
    align-items: flex-start;

    @media (max-width: $mobile-max-screen-width) {
      margin-right: 0;
    }
  }

  .accountInfo {
    align-items: flex-end;

    .walletCardWrapper {
      align-self: center;
    }

    .nameplateDivider {
      border: lightgrey 1px solid;
      margin: 4px 12px;
    }

    .textItems {
      display: flex;
      flex-direction: row;
      justify-content: right;

      .nameplate {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: right;
        align-self: center;

        @include themify {
          color: $header-text-color;
        }
      }

      .nccerNumberLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: right;

        @include themify {
          color: $text-color;
        }
      }
    }

    .profileImageWrapper {
      display: flex;
      flex-wrap: wrap;
      padding-left: 8px;
      align-self: center;

      .profileImage {
        max-height: 72.75px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }
  }

  // & + * {
  //   min-height: calc(100vh - #{$header-collapsed-height});
  // }

  &.editView {
    position: relative;

    .avatar {
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      right: $common-xl-padding;

      @media (max-width: $mobile-max-screen-width) {
        //right: $common-md-padding;
      }
    }
  }
}

// Delete?
// .content {
//   @include setPageContentStyles(false, $common-xl-padding);

//   margin: auto;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   color: black;
//   font-size: 18px;
//   line-height: 22px;
//   text-align: center;
// }

.nccerContainer {
  display: flex;
  align-items: center;

  .nccerTitle {
    margin: 0 $common-xs-padding 0 $common-xs-padding;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    width: $nccer-title-size;
    @media (max-width: $mobile-max-screen-width) {
      display: none;
    }
  }

  .nccerLogo {
    @include setSize($nccer-logo-size);

    align-self: flex-start;
    display: block;

    img {
      height: 100%;
    }

    @media (max-width: $mobile-max-screen-width) {
      display: none;
    }
  }
}

.logo {
  @include setSize($gamsd-logo-size);
  position: absolute;
  top: 15px;
  left: 15px;
  align-self: flex-start;
  display: block;

  img {
    height: 100%;
  }

  @media (max-width: $mobile-max-screen-width) {
    display: none;
  }
}

// .userText {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: $common-xs-padding;

//   & span:not(:last-child) {
//     margin-bottom: calc(#{$common-xs-padding} / 2)
//   }

//   .userName {
//     font-weight: 700;
//     font-size: 42px;
//     line-height: 51px;

//     @media (max-width: $mobile-max-screen-width) {
//       font-size: 24px;
//       line-height: 32px;
//     }
//   }

//   .userPosition {
//     font-weight: 700;
//   }

//   .userMembership {
//     font-style: italic;
//   }
// }

.editButton {
  @include setSize(38px);

  box-shadow: 0 4px 4px rgba(black, 0.25);
  position: absolute;
  right: 0;
  bottom: 3px;
  background-color: white;

  .editButtonIcon {
    @include setSize(24px);
  }
}

.skeleton {
  background-color: white;
}
