$mobile-max-screen-width: 768px;

$nccer-darkblue: #172535;
$nccer-cobalt: #3f5e9d;
$nccer-orange: #fd6a3c;

$primary-blue: #00447c;
$dark-grey: #404040;
$grey: #717171;
$grey-disabled: #bdbdbd;
$light-grey: #e7e7e7;
$lightest-grey: #f1f3f3;
$blue: #0275d8;
$navy: #042e6f;
$violet: #4d046f;
$yellow: #deb54c;
$dark-yellow: #c59008;
$green: #1f7331;
$dark-red: #812020;
$red: #f44336;
$light-green: #b2b136;
$bright-green: #21d467;
$primary-green: #163832;
$primary-blue: #00447c;

$mui-disabled-color: rgba(0, 0, 0, 0.26);
$mui-disabled-bg-color: rgba(0, 0, 0, 0.12);

$common-xs-padding: 8px;
$common-sm-padding: $common-xs-padding * 2; //16px
$common-md-padding: $common-xs-padding * 3; //24px
$common-lg-padding: $common-xs-padding * 4; //32px
$common-xl-padding: $common-xs-padding * 6; //48px
$form-page-footer-padding: 90px;
$common-transition: all 0.3s ease-in-out;
$common-card-box-shadow: 0 15px 30px rgba(black, 0.1);

$sidebar-width: 250px; //335px;
