@import 'src/variables';

.profileViewContainer {
  /* Frame 608 */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  /* System Colors/White */
  background: #ffffff;

  /* System Colors/Container Stroke */
  border: 1px solid #cad2e6;
  border-radius: 5px;

  .rowLabel {
    margin: 0;
    flex: none;
    flex-grow: 0;
  }

  .divider {
    /* System Colors/Container Stroke */
    border: 1px solid #cad2e6;
    margin: 24px 0;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }

  .fieldName {
    font-weight: 400;
    margin: 0;
    display: flex;
    align-items: center;

    /* Primary/Neutral Gray */
    color: #a5a5a5 !important;
    flex: none;
    flex-grow: 0;
  }
  .fieldValue {
    margin-bottom: 0px;

    font-family: 'Poppins';
    line-height: 24px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    color: #080c12;
    flex: none;
    flex-grow: 0;
  }

  .photoDisplay {
    max-width: 360px;

    .photo {
      width: 100%;
    }
  }

  @media (max-width: $mobile-max-screen-width) {
    .row {
      padding-top: 24px;
    }
  }
}
