@import 'src/variables';
@import 'src/mixins';

.viewCredentialsHeader {
  @include themify {
    background-color: $header-background;
  }

  padding: 24px;
  border-bottom: 1px solid #cad2e6;
  position: sticky;

  @media (min-width: $mobile-max-screen-width) {
    padding-bottom: 0;
  }

  .nameplateRow {
    @media (min-width: $mobile-max-screen-width) {
      padding: 16px;
    }
  }
  .printTranscriptWrapper {
    align-items: center;
  }
}

.credentialSubHeaderTextWrapper {
  align-items: center;
  display: flex;
  padding-top: $common-xs-padding * 2;

  .subHeaderActions {
    float: right;
  }
}

.printBtn {
  @media (max-width: $mobile-max-screen-width) {
    width: 100%;
  }
}

.divider {
  /* System Colors/Container Stroke */
  border: 1px solid #cad2e6;
  margin: 24px 0;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}
