@import 'src/variables';
@import 'src/mixins';

.ovHeader {
  background: $nccer-darkblue;
  border-bottom: 1px solid #cad2e6;
  padding: 24px;

  .ovLogo {
    width: 132px;
  }

  .searchedTitle {
    color: #ffffff;
    margin: 0;
    padding-bottom: 8px;
  }
  .searchedDescription {
    color: #ffffff;
    font-weight: 400;
  }
}

.ovSearchedTabs {
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid;
  border-color: #cad2e6;
}

.ovSearchedContent {
  background: #f5f5f5;
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: calc(100vh - 110px); // 110 = static height of header (not responsive)

  .nameplateRow {
    padding: 32px 0 32px 0;
  }
  .downloadTranscriptBtn {
    float: right;
  }

  .carrot {
    padding-left: 14px;
  }
}

.ovContent {
  background: #f5f5f5;
  padding: 48px;
  height: 100vh;

  .unsearchedTitle {
    text-align: center;
    color: #000000;
  }
  .unsearchedDescription {
    text-align: center;
    color: #6a6a6a;
    padding-bottom: 40px;
    font-weight: 400;
  }
}

.searchIcon {
  width: 24px;
  height: 24px;
}
.searchBar {
  background: #ffffff;
}
