@import 'src/variables';

$disable-theme-classname: 'disable-theme';

$nccer-header-background-url: '/assets/images/nccer/login-background.png';
$nccer-login-background-url: '/assets/images/nccer/login-background.png';
$nccer-font-family: 'Poppins';
//$nccer-heading-font-family: 'Poppins';

$themes: (
  'nccer': (
    'primary': $nccer-darkblue,
    'secondary': $nccer-cobalt,
    'sidebar': #ffffff,
    'header': #000000,
    'text': #6a6a6a,
    'font-family': $nccer-font-family,
    // 'heading-font-family': $nccer-heading-font-family,
    'login-background': fixed #183b66 url($nccer-login-background-url) center/cover,
    'header-background': #ffffff,
    //url($nccer-header-background-url) center/cover,
  ),
) !default;

$primary-theme-color: null;
$secondary-theme-color: null;
$header-text-color: null;
$text-color: null;
$sidebar-item-background-color: null;
$login-background: null;
$header-background: null;

@mixin themify($disableThemeOption: false) {
  @each $theme, $colors in $themes {
    @if ($disableThemeOption) {
      :global(.theme-#{$theme}) > *:not(:global(.#{$disable-theme-classname})) & {
        $primary-theme-color: map-get($colors, 'primary') !global;
        $secondary-theme-color: map-get($colors, 'secondary') !global;
        $header-text-color: map-get($colors, 'header') !global;
        $text-color: map-get($colors, 'text') !global;
        $sidebar-item-background-color: map-get($colors, 'sidebar') !global;
        $login-background: map-get($colors, 'login-background') !global;
        $header-background: map-get($colors, 'header-background') !global;

        @content;

        $primary-theme-color: null !global;
        $secondary-theme-color: null !global;
        $header-text-color: null !global;
        $text-color: null !global;
        $sidebar-item-background-color: null !global;
        $login-background: null !global;
        $header-background: null !global;
      }
    } @else {
      :global(.theme-#{$theme}) & {
        $primary-theme-color: map-get($colors, 'primary') !global;
        $secondary-theme-color: map-get($colors, 'secondary') !global;
        $header-text-color: map-get($colors, 'header') !global;
        $text-color: map-get($colors, 'text') !global;
        $sidebar-item-background-color: map-get($colors, 'sidebar') !global;
        $login-background: map-get($colors, 'login-background') !global;
        $header-background: map-get($colors, 'header-background') !global;

        @content;

        $primary-theme-color: null !global;
        $secondary-theme-color: null !global;
        $header-text-color: null !global;
        $text-color: null !global;
        $sidebar-item-background-color: null !global;
        $login-background: null !global;
        $header-background: null !global;
      }
    }
  }
}
