@import 'src/variables';

.container {
  height: 100%;
  padding: $common-md-padding 0 $form-page-footer-padding;
}

.footerContainer {
  @extend .container;

  padding-bottom: $form-page-footer-padding;
}

.divider {
  width: 100%;
  margin: $common-lg-padding 0;
}
